//引入vue
import Vue from 'vue'
//引入vue-router
import VueRouter from 'vue-router'
import { userPath } from './pages/router'

//第三方库需要use一下才能用
Vue.use(VueRouter)

//定义routes路由的集合，数组类型
let routes = []
routes = routes.concat(userPath)

//实例化VueRouter并将routes添加进去
const router=new VueRouter({
    mode: 'history',
    routes
})
//抛出这个这个实例对象方便外部读取以及访问
export default router