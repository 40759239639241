export const userPath = [{
    path: "/home",
    component: () => import( /* webpackChunkName: "privacy" */ './home/index.vue'),
    meta: {
        title: '首页',
        key: 'home_page'
    }
}, {
    path: "/",
    component: () => import( /* webpackChunkName: "privacy" */ './home/index.vue'),
    meta: {
        title: '首页',
        key: 'home_page'
    }
}, {
    path: "/user/privacy",
    component: () => import( /* webpackChunkName: "privacy" */ './Privacy/index.vue'),
    meta: {
        title: '隐私政策',
        key: 'privacy_page'
    }
}, {
    path: "/user/about",
    component: () => import( /* webpackChunkName: "privacy" */ './About/index.vue'),
    meta: {
        title: '关于木槿',
        key: 'abount_page'
    }
},{
    path: "/user/agreement",
    component: () => import( /* webpackChunkName: "agreement" */ './Agreement/index.vue'),
    meta: {
        title: '用户协议',
        key: 'agreement_page'
    },
},{
    path: "/user/contact",
    component: () => import( /* webpackChunkName: "privacy" */ './Contact/index.vue'),
    meta: {
        title: '联系我们',
        key: 'contact_page'
    }
}, {
    path: "/user/feedback",
    component: () => import( /* webpackChunkName: "privacy" */ './Feedback/index.vue'),
    meta: {
        title: '意见反馈',
        key: 'feedback_page'
    }
},{
    path: "/activity/cmb/20200930",
    component: () => import( /* webpackChunkName: "privacy" */ './Activity/Cmb/123/index.vue'),
    meta: {
        title: '招行一网通&木槿校园活动',
        key: 'activity_cmb_page'
    }
}]