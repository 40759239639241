import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import localStore from './utils/storageUtils';
import 'lib-flexible-niuge/flexible'
import infiniteScroll from 'vue-infinite-scroll'
import './assets/css/reset.css'
import './assets/css/common.css'

import {
	Button,
	Checkbox,
	CheckboxGroup,
	Swipe,
	SwipeItem,
	Image as VanImage,
	Lazyload,
	ActionSheet,
	Toast,
	PullRefresh,
	List,
	Empty,
	Grid,
	GridItem,
	ImagePreview,
	Tab,
	Tabs,
	Tag,
	Popup,
	Divider,
	Form,
	Field
} from 'vant';
import 'vant/lib/button/style'
import 'vant/lib/checkbox/style'
import 'vant/lib/checkbox-group/style'
import 'vant/lib/swipe/style'
import 'vant/lib/swipe-item/style'
import 'vant/lib/image/style'
import 'vant/lib/lazyload/style'
import 'vant/lib/action-sheet/style'
import 'vant/lib/toast/style'
import 'vant/lib/tab/style'
import 'vant/lib/tabs/style'
import 'vant/lib/tag/style'
import 'vant/lib/divider/style'
import 'vant/lib/form/style'
import 'vant/lib/field/style'
import 'vant/lib/popup/style'
import 'vant/lib/list/style';
import 'vant/lib/pull-refresh/style';
import 'vant/lib/empty/style';
import 'vant/lib/grid/style';
import 'vant/lib/grid-item/style';
import 'vant/lib/image-preview/style';


Vue.use(Button);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Empty);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(ImagePreview);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VanImage);
Vue.use(Lazyload);
Vue.use(ActionSheet);
Vue.use(Divider);
Vue.use(Toast); //this.$toast('提示文案');
Vue.use(Popup);
Vue.use(Form);
Vue.use(Field);
// 无线滚动
Vue.use(infiniteScroll)

Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
router.afterEach((to) => {
	console.log("router.afterEach==>" + to)
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
})